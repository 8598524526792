@import '../../CommonStyles/ConstantsAndMixins.scss';

.korio-app .player-settings {
    $file-name-height: 40px;
    $header-padding: 5px;
    $tab-bar-height: 50px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include fadeInFast;

    .settings-header {
        display: flex;
        border-top: solid 1px white;
        margin: 0 20px;
        padding: $header-padding 0;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;

        .file-name {
            font-size: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: $file-name-height;
            line-height: $file-name-height;
            box-sizing: border-box;
        }

        .menu-button {
            border-radius: 50%;
            cursor: pointer;
            width: 40px;
            height: 40px;
            transition: all 300ms ease-in-out;
            transition-property: width, padding, border, opacity;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;

            &.open {
                background-color: black;
                
                svg {
                    color: white;
                }
            }

            &.hidden {
                width: 0;
                padding: 0;
                border: 0;
                opacity: 0;
                pointer-events: none;
                cursor: unset;
            }
        }
    }

    .modal-overlay {
        @include overlay;

        .modal {    
            @include modal;
    
            .modal-buttons {
                display: flex;
                padding: 10px 0;
                width: 100%;
                justify-content: space-around;
            }
        }
    }
 
    .swipeable-tabs-container, .static-tabs-container {
        height: $picker-height + 30px + $tab-bar-height;
        width: 100%;
        transition: height 300ms ease-in-out;
        overflow: hidden;

        &.hidden {
            height: 0;
        }
    }

    .swipeable-views, .react-swipeable-view-container {
        height: $picker-height + 30px;
        width: 100%;
    }

    .static-tabs-container {
        display: flex;
        align-items: center;
    }

    .swipeable-tabs-container {
        display: flex;
        flex-direction: column;

        .tab-bar {
            display: flex;
            width: 100%;
            background-color: black;
            border-top: solid 2px white;
            height: $tab-bar-height;

            .tab {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px 8px;
                font-size: 20px;
                background-color: black;
                color: white;
                transition: all 300ms ease-in-out;
                transition-property: background-color, color;
                cursor: pointer;

                &.selected {
                    background-color: white;
                    color: black;
                }
            }
        }
    }

    .count-settings, .loop-settings, .effect-settings {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}