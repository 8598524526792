@import '../../CommonStyles/ConstantsAndMixins.scss';

.korio-app .player-overlay {
    position: absolute;
    width: 100%;
    top: $player-controls-height;
    height: 200px + $count-canvas-height + $canvas-spacing;
    transition: height 300ms ease-in-out;
    display: flex;
    pointer-events: none;

    &.paused {
        height: 100px + $count-canvas-height + $canvas-spacing;
    }

    .start-marker {
        width: 3px;
        z-index: 2;
        height: 100%;

        @include softDivGlow($white);
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 50%;
        height: 100%;
        box-sizing: border-box;

        .value {
            transition: all 300ms ease-in-out;
            transition-property: font-size, margin-bottom;
            font-size: 60px;
            margin-bottom: -10px;
        }

        .label {
            transition: font-size 300ms ease-in-out;
            font-size: 22px;
        }

        &.left {
            align-items: flex-start;
            padding-left: 5px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), transparent);

            .count-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;

                &.large {
                    .value {
                        font-size: 120px;
                        margin-bottom: -25px;
                    }

                    .label {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .time-section {
        $padding: 20px;

        display: flex;
        position: absolute;
        right: $padding;
        bottom: -($time-section-height + $padding/2);
        height: $time-section-height;
        font-size: 20px;

        .slash {
            margin: 0 5px;
        }
    }
}