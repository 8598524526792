$header-height: 15%;
$white: #ffffff;
$blue: rgb(67, 223, 250);
$pink: #f390e6;
$yellow: #FFDD1B;
$orange: #FF9900;
$green: #B6FF00;
$canvas-height: 200px;
$count-canvas-height: 50px;
$canvas-spacing: 5px;
$player-settings-height: 275px;
$player-controls-height: 90px;
$time-section-height: 30px;
$picker-height: 135px;

@mixin overlay() {
    @include fadeInFast;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

@mixin modalStyle($font-size, $width) {
    min-width: 300px;
    width: $width;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid white 1px;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: $font-size / 3;

    .modal-title {
        font-size: $font-size;
    }

    .modal-subtitle {
        font-size: $font-size * 2/3;
        margin-bottom: 30px;
    }

    .modal-text {
        .modal-subheader {
            font-size: $font-size * 2/3;
        }

        > div {
            font-size: $font-size * 2/5;
            margin: 10px 0;
            text-align: center;
        }
    }

    button {
        @include buttonStyle;

        font-size: $font-size / 3;
        margin: 20px 0;
    }
}

@mixin modal() {
    @media screen and (min-width: 992px) {
        $font-size: 60px;
        $width: 640px;
        min-width: $width;

        @include modalStyle($font-size, $width);
    }

    /* start of large tablet styles */
    @media screen and (max-width: 991px) and (min-width: 480px) {
        $font-size: 45px;
        $width: 480px;
        min-width: $width;

        @include modalStyle($font-size, $width);
    }
    
    /* start of phone styles */
    @media screen and (max-width: 479px) {
        $font-size: 30px;
        $width: 320px;
        min-width: $width;

        @include modalStyle($font-size, $width);
    }
}

@mixin buttonStyle() {
    border-radius: 10px;
    padding: 5px 10px;
    font-family: 'Amazonas-Centered';
    background-color: white;
    height: 20px;
    line-height: 20px;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 16px;
    @include noSelect;
    outline: none;

    &:hover {
        background-color: black;
        color: white;
    }
}

@mixin hideNumberArrows() {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

@mixin noSelect() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin neonRainbow() {
    -webkit-animation: neon-rainbow 12s ease-in-out infinite;
    -moz-animation: neon-rainbow 12s ease-in-out infinite;
    animation: neon-rainbow 12s ease-in-out infinite; 

    @include keyframes(neon-rainbow) {
        0% {
            @include brightDivGlow($white);
        }
        8% {
            @include softDivGlow($white);
        }
        16% {
            @include brightDivGlow($blue);
        }
        24% {
            @include softDivGlow($blue);
        }
        33% {
            @include brightDivGlow($pink);
        }
        41% {
            @include softDivGlow($pink);
        }
        49% {
            @include brightDivGlow($yellow);
        }
        57% {
            @include softDivGlow($yellow);
        }
        66% {
            @include brightDivGlow($orange);
        }
        74% {
            @include softDivGlow($orange);
        }
        82% {
            @include brightDivGlow($green);
        }
        90% {
            @include softDivGlow($green);
        }
        100% {
            @include brightDivGlow($white);
        }
    }
}

@mixin neonText($color) {
    $color-id: unique_id();

    -webkit-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    -moz-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    animation: neon-#{$color-id} 2s ease-in-out infinite alternate;  
    
    @include keyframes(neon-#{$color-id}) {
        from {
            @include brightTextGlow($color);
        }
        to {
            @include softTextGlow($color);
        }
    }
}

@mixin neonDiv($color) {
    $color-id: unique_id();
    
    background-color: $color;
    -webkit-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    -moz-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    animation: neon-#{$color-id} 2s ease-in-out infinite alternate;  
    
    @include keyframes(neon-#{$color-id}) {
        from {
            @include brightDivGlow($color);
        }
        to {
            @include softDivGlow($color);
        }
    }
}

@mixin neonBorder($color, $width) {
    $color-id: unique_id();
    
    border-color: $color;
    -webkit-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    -moz-animation: neon-#{$color-id} 2s ease-in-out infinite alternate;
    animation: neon-#{$color-id} 2s ease-in-out infinite alternate;  
    
    @include keyframes(neon-#{$color-id}) {
        from {
            @include brightBorderGlow($color, $width);
        }
        to {
            @include softBorderGlow($color, $width);
        }
    }
}

@mixin keyframes( $animationName ) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin brightBorderGlow($color, $width) {
    $adjusted-px: $width / 500;

    border-color: $color;
    box-shadow: 0 0 15*$adjusted-px #fff, 0 0 30*$adjusted-px #fff, 0 0 15*$adjusted-px #fff inset, 0 0 30*$adjusted-px $color inset;
}

@mixin softBorderGlow($color, $width) {
    $adjusted-px: $width / 500;

    border-color: $color;
    box-shadow: 0 0 10*$adjusted-px #fff, 0 0 20*$adjusted-px #fff, 0 0 10*$adjusted-px #fff inset, 0 0 20*$adjusted-px $color inset;
}

@mixin brightDivGlow($color) {
    background-color: $color;
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px $color, 0 0 70px $color, 0 0 80px $color, 0 0 100px $color, 0 0 150px $color;
}

@mixin softDivGlow($color) {
    background-color: $color;
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $color, 0 0 35px $color, 0 0 40px $color, 0 0 50px $color, 0 0 75px $color;
}

@mixin brightTextGlow($color) {
    color: $color;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px $color, 0 0 70px $color, 0 0 80px $color, 0 0 100px $color, 0 0 150px $color;
}

@mixin softTextGlow($color) {
    color: $color;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $color, 0 0 35px $color, 0 0 40px $color, 0 0 50px $color, 0 0 75px $color;
}

@mixin fadeIn() {
    -webkit-animation: fadeKeyframes 1s ease-in-out;
    -moz-animation: fadeKeyframes 1s ease-in-out;
    animation: fadeKeyframes 1s ease-in-out;  

    @include keyframes(fadeKeyframes) {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin fadeInFast() {
    -webkit-animation: fadeKeyframes 300ms ease-in-out;
    -moz-animation: fadeKeyframes 300ms ease-in-out;
    animation: fadeKeyframes 300ms ease-in-out;  

    @include keyframes(fadeKeyframes) {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}