@import '../CommonStyles/ConstantsAndMixins.scss';

.korio-app .music-player-and-settings {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .music-player-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 1rem 0;
        
        .music-player {
            @include fadeInFast;
            @include noSelect;

            $height: $canvas-height + $count-canvas-height + $canvas-spacing + $player-controls-height + $time-section-height;
        
            position: relative;
            width: 100%;
            height: $height;
            transition: height 300ms ease-in-out;

            &.paused {
                height: $height - $canvas-height/2;
            }
        
            .controls-and-progress {
                display: flex;
                flex-direction: column;
            }
        }
    }
}