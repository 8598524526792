@import '../CommonStyles/ConstantsAndMixins.scss';

.korio-app .icon-wrapper {
    $dropzone-height: 400px;
    $wrapper-height: 300px;

    @include fadeInFast;

    position: absolute;
    height: $wrapper-height;
    top: calc(50% - #{$wrapper-height}/2);
    z-index: 1;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;

    &.showing-button {
        pointer-events: unset;
    }

    .icon {
        border: solid 6px white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
    }

    .message {
        margin: 10px 0;
        color: white;
        font-size: 25px;
    }

    .try-again {
        @include buttonStyle;
    }
}