@import '../../CommonStyles/ConstantsAndMixins.scss';

.korio-app .player-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $player-controls-height;
    padding-bottom: 10px;
    box-sizing: border-box;

    .control {
        transition: opacity 300ms ease-in-out;
        margin: 1rem;
        opacity: 1;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}