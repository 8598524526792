@import '../CommonStyles/ConstantsAndMixins.scss';

.korio-app {
    .dropzone {
        /* start of laptop styles */
        @media screen and (min-width: 992px) {
            width: 640px;
        }
    
        /* start of large tablet styles */
        @media screen and (max-width: 991px) and (min-width: 480px) {
            width: 480px;
        }
            
        /* start of phone styles */
        @media screen and (max-width: 479px) {
            width: 320px;
        }

        height: 400px;
        border: dashed white 6px;
        border-radius: 10px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &:hover, &.drag-enter {
            background-color: rgba(255, 255, 255, 0.4);
        }

        &:hover:not(.drag-enter) {
            cursor: pointer;
        }

        @include fadeInFast;
    }
}