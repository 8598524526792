@import '../CommonStyles/ConstantsAndMixins.scss';

@mixin intro($font-size, $width-param) {
    $play-size: $font-size;
    $border-width: 6 * $font-size / 100;
    $letters-height: $width-param / 3;
    $letters-width: $width-param;

    $vertical-adjuster: $letters-height / 200; // this was 1px in original dimensions
    $horizontal-adjuster: $letters-width / 600; // this was 1px in original dimensions

    position: relative;
    height: $letters-height;
    width: $letters-width;
    @include fadeInFast;

    .korio-letter {
        font-family: 'Amazonas-Centered';
        color: $white;
        font-size: $play-size;
        line-height: 0.8;
        margin: 0;
        position: absolute;
    }

    .k-wrapper {
        $height: $play-size;
        $width: $play-size;

        position: absolute;
        height: $height;
        width: $width;
        left: calc(50% - #{$width} / 2);
        top: calc(50% - #{$height} / 2);

        &.rotate {
            transition: transform 750ms ease-in-out;
            transform: rotate(180deg);
        }

        &.slide-left {
            transition: left 1s ease-in-out;
            left: 0;
        }

        .k {
            position: absolute;
            font-size: $play-size;
            height: $play-size;
            width: $play-size;
    
            .k1 {
                position: absolute;
                left: $play-size / 3 - 5*$horizontal-adjuster;
                top: $play-size / 6 - 1.2*$vertical-adjuster;

                @-moz-document url-prefix() {
                    top: $play-size /6 - 2.5*$vertical-adjuster;
                }
    
                &.morph {
                    transition: left 750ms ease-in-out;
                    left: $play-size * 2/3;
                }

                &.neon {
                    transition: none;
                    @include softTextGlow($blue);
                }
            }
    
            .k2 {
                $original-left: $play-size / 3 - 7*$horizontal-adjuster;

                position: absolute;
                left: $original-left;
                top: $play-size / 6 - 6*$vertical-adjuster;
                transform: rotate(-90deg) scale(1.04, 0.7);
    
                &.morph {
                    transition: left 750ms ease-in-out;
                    left: $original-left - 12*$horizontal-adjuster;
                }

                &.neon {
                    transition: none;
                    @include softTextGlow($blue);
                }
            }
        }
    }

    .o2 {
        $height: $play-size * 1.2;
        $width: $play-size * 1.2;

        border: solid $border-width $white;
        border-radius: 50%;
        box-sizing: border-box;
        height: $height;
        width: $width;
        position: absolute;
        left: calc(50% - #{$width} / 2);
        top: calc(50% - #{$height} / 2);

        &.slide-right {
            $new-height: $play-size * 0.8;

            transition: all 1s ease-in-out;
            transition-property: left, top, height, width;
            left: $letters-width - $width + 12*$horizontal-adjuster;
            top: calc(50% - #{$new-height} / 2 - 2*#{$vertical-adjuster});
            height: $new-height;
            width: $play-size * 0.8;
        }

        &.neon {
            transition: none;
            @include softBorderGlow($green, $width-param);
        }
    }

    .r {
        $height: $play-size * 0.8;
        $width: $play-size * 0.7;

        font-family: 'Beon';
        height: $height;
        width: $width;
        left: calc(50% - #{$width} / 2 + 4*#{$horizontal-adjuster});
        top: calc(50% - #{$height} / 2);
        opacity: 0;

        &.fade-in {
            transition: opacity 1s ease-in-out;
            opacity: 1;
        }

        &.neon {
            transition: none;
            @include softTextGlow($yellow);
        }
    }

    .o1 {
        $height: $play-size * 0.8;
        $width: $play-size * 0.8;

        border: solid $border-width $white;
        border-radius: 50%;
        box-sizing: border-box;
        height: $height;
        width: $width;
        position: absolute;
        left: calc(28% - #{$width} / 2);
        top: calc(50% - #{$height} / 2);
        opacity: 0;

        &.fade-in {
            transition: opacity 1s ease-in-out;
            opacity: 1;
        }

        &.neon {
            transition: none;
            @include softBorderGlow($pink, $width-param);
        }
    }

    .i {
        $height: $play-size * 0.8;
        $width: $play-size * 0.2;

        height: $height;
        width: $width;
        left: calc(70% - #{$width} / 2 - 2*#{$horizontal-adjuster});
        top: calc(50% - #{$height} / 2 + 2*#{$vertical-adjuster});
        opacity: 0;

        &.fade-in {
            transition: opacity 1s ease-in-out;
            opacity: 1;
        }

        &.neon {
            transition: none;
            @include softTextGlow($orange);
        }
    }
}

.korio-app .intro {
    position: absolute;
    top: calc(50% - #{$header-height} / 2);
    left: 0;
    right: 0;
    margin: auto;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;

    &.move-to-top {
        transition: top 1s ease-in-out;
        top: 0;
    }

    .korio-letters {
        /* start of laptop styles */
        @media screen and (min-width: 992px) {
            $font-size: 100px;
            $width: 640px;
            min-width: $width;

            @include intro($font-size, $width);
        }

        /* start of large tablet styles */
        @media screen and (max-width: 991px) and (min-width: 480px) {
            $font-size: 75px;
            $width: 480px;
            min-width: $width;

            @include intro($font-size, $width);
        }
        
        /* start of phone styles */
        @media screen and (max-width: 479px) {
            $font-size: 50px;
            $width: 320px;
            min-width: $width;

            @include intro($font-size, $width);
        }
    }
}