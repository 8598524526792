@import './CommonStyles/ConstantsAndMixins';

@font-face {
    font-family: 'Beon';
    src: local('Beon'), url(./resources/fonts/Beon.otf) format('opentype');
}

@font-face {
    font-family: 'Amazonas';
    src: local('Amazonas'), url(./resources/fonts/amazonas/Amazonas.ttf) format('truetype');
}

@font-face {
    font-family: 'Amazonas-Centered';
    src: local('Amazonas-Centered'), url(./resources/fonts/amazonas/amazonas-webfont.woff) format('woff');
}

body {
    background: url('./resources/brick1.jpg');
    background-color: black;
    background-repeat: repeat;
    background-size: cover;

    .react-tiny-popover-container {
        z-index: 1;
        
        .menu {
            background-color: white;
            border-radius: 10px;
            overflow: hidden;

            .option {
                padding: 8px;
                font-family: 'Amazonas-Centered';
                font-size: 20px;
                border-radius: 10px;
                border: solid 1px white;
            
                &:hover:not(.disabled) {
                    background-color: black;
                    color: white;
                    cursor: pointer;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }
    }

    .korio-app {
        touch-action: manipulation;
        color: white;
        font-family: 'Amazonas-Centered';
        overflow: hidden;
        box-sizing: border-box;
        min-height: 640px;
        min-width: 320px;

        :not(input) {
            @include noSelect;
        }

        .main-content-wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .overlay {
            @include overlay;

            .modal {
                @include modal;

                .modal-subtitle {
                    border-top: solid 1px white;
                }
            }
        }
    }
}