@import '../../../CommonStyles/ConstantsAndMixins.scss';

.korio-app .picker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid white;
    border-radius: 8px;
    padding: 10px;
    padding-top: 25px;
    position: relative;
    height: $picker-height;
    box-sizing: border-box;

    &.disabled {
        opacity: 0.5;

        .checkbox {
            cursor: unset !important;
        }
    }

    &.offset {
        border-color: $blue;

        .label {
            border-color: $blue;
        }
    }

    &.bpm {
        border-color: $pink;

        .label {
            border-color: $pink;
        }
    }

    &.start-time {
        border-color: $yellow;

        .label {
            border-color: $yellow;
        }
    }

    &.end-time {
        border-color: $orange;

        .label {
            border-color: $orange;
        }
    }

    &.speed {
        border-color: $green;

        .label {
            border-color: $green;
        }
    }

    .label {
        font-size: 20px;
        position: absolute;
        top: -15px;
        background-color: black;
        padding: 2px;
        width: 90px;
        border: 1px solid white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .time-picker-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;

        .colon {
            position: absolute;
            top: 45px;
        }
    }

    .picker {
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2px;

        @include hideNumberArrows;

        &.time-picker {
            width: 45px;
        }

        &.minute-picker {
            margin-right: 5px;

            .number-display {
                text-align: right;
                padding-right: 5px;
            }
        }

        .checkbox {
            .beta {
                background-color: $blue;
                padding: 2px 10px;
                border-radius: 20px;
                margin-bottom: 5px;
                color: black;
            }

            font-size: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include noSelect;
        }

        .number-display {
            text-align: center;
            font-size: 30px;
            background-color: transparent;
            color: white;
            border: 0;
            font-family: 'Amazonas-Centered';
            width: 100%;
            padding: 0;
            box-shadow: none;
            margin: 0;
        }

        .increment, .decrement {
            background-color: black;
            border-radius: 6px;
            border: 1px solid white;
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;

            &.disabled {
                opacity: 0.5;
            }

            &.pressed:not(.disabled) {
                background-color: white;

                svg {
                    color: black;
                }
            }
        }
    }
}