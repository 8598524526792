@import '../../CommonStyles/ConstantsAndMixins.scss';

.korio-app .progress-display {
    position: relative;
    width: 100%;
    height: $canvas-height;
    margin-left: 50%;
    transition: height 300ms ease-in-out;

    &.paused {
        height: $canvas-height / 2;
    }

    .react-draggable {
        height: 100%;

        .canvas-wave {
            height: 100%;
        }

        .canvas-counts {
            height: $count-canvas-height;
        }

        .start-loop-marker, .end-loop-marker {
            width: 2px;
            z-index: 1;
            position: absolute;
            height: calc(100% + #{$count-canvas-height} + #{$canvas-spacing});
            top: 0;
            transition: opacity 300ms ease-in-out;

            &.hidden {
                opacity: 0;
            }
        }

        .offset-count-marker, .bpm-count-marker {
            position: absolute;
            z-index: 1;
            bottom: -($count-canvas-height + $canvas-spacing + 25px);
            width: 40px;
            display: flex;
            justify-content: center;
            transition: opacity 300ms ease-in-out;

            &.hidden {
                opacity: 0;
            }
        }

        .start-loop-marker {
            @include softDivGlow($yellow);
        }
        
        .end-loop-marker {
            @include softDivGlow($orange);
        }
    }
}